import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import ROUTES from '@constants/routes';

function PrivateRoute(props) {
  const accessToken = localStorage.getItem('accessToken');

  return accessToken
    ? <Route {...props} />
    : <Redirect to={ROUTES.LOGIN} />;
}

export default PrivateRoute;
