import {
  GET_RANKING,
  GET_COUNTRIES,
  UPDATE_COUNTRIES,
  GET_QUESTIONS,
  UPDATE_QUESTIONS,
  GET_TOPICS,
  UPDATE_TOPICS,
  GET_BRAND_TYPES,
  UPDATE_BRAND_TYPES,
  GET_BRANDS_BY_TYPE,
  UPDATE_BRANDS_BY_TYPE,
  SET_PROFILE_BRANDS,
  POST_PROFILE,
  UPDATE_PROFILE,
  GET_HISTORY_PLAYS,
  GET_LEVEL_TEAM,
  POST_ANSWERS,
} from '@constants/actionNames';

const initialState = {
  rankingError: false,
  rankingData: { top: [], normal: [] },
  countriesData: false,
  countriesLoading: false,
  countriesError: false,
  questionsData: false,
  questionsLoading: false,
  questionsError: false,
  topicsData: false,
  topicsLoading: false,
  topicsError: false,
  brandTypesData: false,
  brandTypesLoading: false,
  brandTypesError: false,
  brandsByTypeData: false,
  brandsByTypeLoading: false,
  brandsByTypeError: false,
  profileData: false,
  answersData: false,
  profileLoading: false,
  profileError: false,
  updateProfileData: false,
  updateProfileLoading: false,
  updateProfileError: false,
  profileBrands: false,
  historyPlaysData: false,
  levelTeamData: false,
};

let subcategories = false;

const setSelectedBrands = (brands, userBrands = []) => {
  const userBrandsIds = userBrands.map(userBrand => userBrand.id);
  return brands.map(brand => ({
    ...brand,
    selected: userBrandsIds.includes(brand.id),
  }));
};

const getBrandsByType = (state, payload) => {
  if (payload.brandsByTypeData) {
    subcategories = { ...subcategories } || {};
    subcategories[payload.brandsByTypeData[0].brandTypeId] = setSelectedBrands([
      ...payload.brandsByTypeData],
    [...state.profileBrands]);
  }

  return { ...state, ...payload, brandsByTypeData: subcategories };
};

const getUpdatedBrandsByType = (state, payload) => {
  subcategories = { ...subcategories };
  subcategories[payload[0].brandTypeId] = payload;
  return { ...state, brandsByTypeData: subcategories };
};

const postReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_COUNTRIES:
      return { ...state, ...payload };
    case UPDATE_COUNTRIES:
      // In this case payload has only countriesData, not error or loading
      return { ...state, countriesData: payload };
    case GET_QUESTIONS:
      return { ...state, ...payload };
    case GET_LEVEL_TEAM:
      return { ...state, ...payload };
    case UPDATE_QUESTIONS:
      return { ...state, questionsData: payload };
    case GET_TOPICS:
      return { ...state, ...payload };
    case UPDATE_TOPICS:
      return { ...state, topicsData: payload };
    case GET_BRAND_TYPES:
      return { ...state, ...payload };
    case UPDATE_BRAND_TYPES:
      return { ...state, brandTypesData: payload };
    case GET_BRANDS_BY_TYPE:
      return getBrandsByType(state, payload);
    case UPDATE_BRANDS_BY_TYPE:
      return getUpdatedBrandsByType(state, payload);
    case SET_PROFILE_BRANDS:
      return { ...state, profileBrands: payload };
    case POST_PROFILE:
      return { ...state, ...payload };
    case POST_ANSWERS:
      return { ...state, ...payload };
    case UPDATE_PROFILE:
      return { ...state, ...payload };
    case GET_RANKING:
      return { ...state, ...payload };
    case GET_HISTORY_PLAYS:
      return { ...state, ...payload };
    default:
      return state;
  }
};

export default postReducer;
