import {
  GET_TEST, GET_TEST_RESULT, POST_TEST_QUESTION,
  POST_NEXT_LEVEL, CLEAN_TEST_RESULT_DATA,
} from '@constants/actionNames';

const initialState = {
  testData: false,
  testLoading: false,
  testError: false,
  getTestResultData: false,
  getTestResultLoading: false,
  getTestResultError: false,
  postNextLevelData: false,
  postTestQuestionData: false,
  postTestQuestionLoading: false,
  postTestQuestionError: false,
};

const testReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_TEST:
      return { ...state, ...payload };
    case GET_TEST_RESULT:
      return { ...state, ...payload };
    case POST_TEST_QUESTION:
      return { ...state, ...payload };
    case POST_NEXT_LEVEL:
      return { ...state, ...payload };
    case CLEAN_TEST_RESULT_DATA:
      return { ...state, ...payload };
    default:
      return state;
  }
};

export default testReducer;
