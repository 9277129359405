import {
  combineReducers, createStore, compose, applyMiddleware,
} from 'redux';

import authReducer from '@redux/auth/reducer';
import profileReducer from '@redux/profile/reducer';
import gameReducer from '@redux/game/reducer';
import testReducer from '@redux/test/reducer';
import responseMiddleware from 'redux-response-middleware';

const reducers = combineReducers({
  authReducer, profileReducer, gameReducer, testReducer,
});

const middlewares = [];
const enhancers = [];

middlewares.push(responseMiddleware());

enhancers.push(applyMiddleware(...middlewares));

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // eslint-disable-line

export default createStore(reducers, composeEnhancers(...enhancers));
