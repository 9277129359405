export const CREATE_USER = 'CREATE_USER';
export const DELETE_BUDGET = 'DELETE_BUDGET';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
// -- #GP --
export const GET_GP_PRODUCT_LIST = 'GET_GP_PRODUCT_LIST';
export const GET_GP_SERVICE_LIST = 'GET_GP_SERVICE_LIST';
export const PAY_GP_TX = 'PAY_GP_TX';
export const REFUND_GP = 'REFUND_GP';
export const SEARCH_PAYMENT = 'SEARCH_PAYMENT';
export const SEND_GP_TX = 'SEND_GP_TX';
export const SEND_MAIL_GP = 'SEND_MAIL_GP';
export const SEND_MAIL_GP_REFUND = 'SEND_MAIL_GP_REFUND';
// -- GP end--
export const GET_USERS = 'GET_USERS';
export const GET_RANKING = 'GET_RANKING';
export const GET_POSTS = 'GET_POSTS';
export const ADD_POST = 'ADD_POST';
export const GET_COUNTRIES = 'GET_COUNTRIES';
export const UPDATE_COUNTRIES = 'UPDATE_COUNTRIES';
export const GET_QUESTIONS = 'GET_QUESTIONS';
export const POST_EMAIL_MARKETPLACE = 'POST_EMAIL_MARKETPLACE';
export const UPDATE_QUESTIONS = 'UPDATE_QUESTIONS';
export const GET_TOPICS = 'GET_TOPICS';
export const UPDATE_TOPICS = 'UPDATE_TOPICS';
export const GET_BRAND_TYPES = 'GET_BRAND_TYPES';
export const UPDATE_BRAND_TYPES = 'UPDATE_BRAND_TYPES';
export const GET_BRANDS_BY_TYPE = 'GET_BRANDS_BY_TYPE';
export const UPDATE_BRANDS_BY_TYPE = 'UPDATE_BRANDS_BY_TYPE';
export const POST_PROFILE = 'POST_PROFILE';
export const POST_ANSWERS = 'POST_ANSWERS';
export const GET_GAME = 'GET_GAME';
export const POST_GAME = 'POST_GAME';
export const POST_TIME_IS_OVER = 'POST_TIME_IS_OVER';
export const GET_TEST = 'GET_TEST';
export const GET_TEST_RESULT = 'GET_TEST_RESULT';
export const GET_USER = 'GET_USER';
export const POST_TEST_QUESTION = 'POST_TEST_QUESTION';
export const SET_PROFILE_BRANDS = 'SET_PROFILE_BRANDS';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const RECOVER_PASSWORD = 'RECOVER_PASSWORD';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const GET_ALL_LEVELS = 'GET_ALL_LEVELS';
export const GET_ALL_PRODUCTS = 'GET_ALL_PRODUCTS';
export const GET_LEVELS = 'GET_LEVELS';
export const UPDATE_USER_TEAM = 'UPDATE_USER_TEAM';
export const CLEAN_PROFILE_UPDATE = 'CLEAN_PROFILE_UPDATE';
export const TOGGLE_NEXT_LEVEL = 'TOGGLE_NEXT_LEVEL';
export const POST_NEXT_LEVEL = 'POST_NEXT_LEVEL';
export const CLEAN_TEST_RESULT_DATA = 'CLEAN_TEST_RESULT_DATA';
export const UPLOAD_PICTURE = 'UPLOAD_PICTURE';
export const GET_HISTORY_PLAYS = 'GET_HISTORY_PLAYS';
export const CLEAN_SKILL_ALERT = 'CLEAN_SKILL_ALERT';
export const HAVE_ONE_SKILL = 'HAVE_ONE_SKILL';
export const GET_PRODUCT = 'GET_PRODUCT';
export const SEND_EMAIL_PRODUCT = 'SEND_EMAIL_PRODUCT';
export const CLEAN_NO_FUND_ALERT = 'CLEAN_NO_FUND_ALERT';
export const DELETE_ACCOUNT = 'DELETE_ACCOUNT';
export const GET_MISSION = 'GET_MISSION';
export const COMPLETE_MISSION = 'COMPLETE_MISSION';
// -- #GROUPS --
export const GET_GROUPS = 'GET_GROUPS';
export const GET_PREMIUM_GROUP_DETAIL = 'GET_PREMIUM_GROUP_DETAIL';
export const REMOVE_GROUP = 'REMOVE_GROUP';
export const GET_GROUP = 'GET_GROUP';
export const JOIN_GROUP = 'JOIN_GROUP';
export const LEAVE_GROUP = 'LEAVE_GROUP';
export const REMOVE_USER_FROM_GROUP = 'REMOVE_USER_FROM_GROUP';
export const GET_ADMIN_GROUP_DATA = 'GET_ADMIN_GROUP_DATA';
// -- GROUPS END --
export const SEARCH_USER = 'SEARCH_USER';
export const SAVE_GROUP = 'SAVE_GROUP';
export const SEND_INVITATION = 'SEND_INVITATION';
export const GET_REFERRALS = 'GET_REFERRALS';
export const GET_NEWS = 'GET_NEWS';
export const GET_COURSES = 'GET_COURSES';
export const GET_BENEFITS = 'GET_BENEFITS';
export const GET_COURSE = 'GET_COURSE';
export const GET_COURSE_TEST = 'GET_COURSE_TEST';
export const CHECK_LESSON = 'CHECK_LESSON';
export const GET_BUDGET = 'GET_BUDGET';
export const SAVE_BUDGET = 'SAVE_BUDGET';
export const GET_MISSIONS = 'GET_MISSIONS';
export const SAVE_GOAL = 'SAVE_GOAL';
export const GET_PROFILE_DETAIL = 'GET_PROFILE_DETAIL';
export const GET_CATEGORIES = 'GET_CATEGORIES';
export const GET_USER_GROUP_TESTS = 'GET_USER_GROUP_TESTS';
export const GET_COURSES_SHORT = 'GET_COURSES_SHORT';
export const GET_WORLDS = 'GET_WORLDS';
export const SAVE_WORLDS = 'SAVE_WORLDS';
export const UPDATE_WORLD = 'UPDATE_WORLD';
export const GET_PRODUCTS_BY_REGION = 'GET_PRODUCTS_BY_REGION';
export const GET_REGIONS = 'GET_REGIONS';
export const GET_PRODUCT_BY_ID = 'GET_PRODUCT_BY_ID';
export const GET_NEW_PROFILE = 'GET_NEW_PROFILE';
export const GET_LEVEL_TEAM = 'GET_LEVEL_TEAM';
export const TOGGLE_TIMER = 'TOGGLE_TIMER';
// -- #BBVA --
export const GET_ALL_DOCUMENT_TYPES = 'GET_ALL_DOCUMENT_TYPES';
export const GET_USER_BBVA_PRODUCTS = 'GET_USER_BBVA_PRODUCTS';
export const SET_USER_BBVA_PRODUCT = 'SET_USER_BBVA_PRODUCT';
// -- #V3 --
export const GET_USER_MEDALS = 'GET_USER_MEDALS';
export const GET_USER_SKILLS = 'GET_USER_SKILLS';
export const GET_USER_LEVEL_PROGRESS = 'GET_USER_LEVEL_PROGRESS';
export const GET_USER_DAILY_PROGRESS = 'GET_USER_DAILY_PROGRESS';
export const GET_USER_STATISTICS = 'GET_USER_STATISTICS';
export const GET_MOTIVES = 'GET_MOTIVES';
export const GET_METAS = 'GET_METAS';
export const UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE';
export const UPDATE_MOTIVES = 'UPDATE_MOTIVES';
export const UPDATE_METAS = 'UPDATE_METAS';
export const SEARCH_USERS = 'SEARCH_USERS';
export const GET_GROUPS_SEARCH_LIST = 'GET_GROUPS_SEARCH_LIST';
export const SET_CURRENT_LESSON = 'SET_CURRENT_LESSON';
export const GET_CURRENT_LESSON = 'GET_CURRENT_LESSON';
export const GET_USER_EVALUATIONS = 'GET_USER_EVALUATIONS';
export const POST_NEW_USER_EVALUATION = 'POST_NEW_USER_EVALUATION';
export const SET_PACE = 'SET_PACE';
export const SET_LIVES = 'SET_LIVES';
export const SET_MEDALS = 'SET_MEDALS';
