import {
  GET_GAME,
  POST_GAME,
  POST_TIME_IS_OVER,
  CLEAN_NO_FUND_ALERT,
  TOGGLE_TIMER,
} from '@constants/actionNames';

const initialState = {
  timerData: false,
  gameData: false,
  gameLoading: false,
  gameError: false,
  postGameData: false,
  postGameLoading: false,
  postGameError: false,
  postTimeIsOver: false,
  postTimeIsOverLoading: false,
  postTimeIsOverError: false,
};
const gameReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case 'CLEAN_GAME':
      return { ...state, ...payload };
    case 'CLEAN_SKILL_ALERT': {
      const newState = { ...state };
      newState.postGameData.showAlert = false;
      return newState;
    }
    case CLEAN_NO_FUND_ALERT: {
      const newState = { ...state };
      newState.postGameData.showAlertNoFund = false;
      return newState;
    }
    case GET_GAME:
      return { ...state, ...payload };
    case POST_GAME:
      return { ...state, ...payload };
    case POST_TIME_IS_OVER: {
      const newState = { ...state };
      newState.postGameData = payload.timeIsOverData;
      return newState;
    }
    case TOGGLE_TIMER: {
      const newState = { ...state };
      newState.timerData = payload.data;
      return newState;
    }
    default:
      return state;
  }
};

export default gameReducer;
