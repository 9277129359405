import React from 'react';
import { withRouter } from 'react-router-dom';
import ReactGA from 'react-ga';

class GoogleAnalytics extends React.Component {
  componentDidUpdate({ location, history }) {
    if (location.pathname === this.props.location.pathname) {
      return;
    }

    if (history.action === 'PUSH' && process.env.REACT_APP_GA_ID) {
      ReactGA.pageview(`${window.location.pathname} ${window.location.search}`);
    }
  }

  render() {
    return null;
  }
}

export default withRouter(GoogleAnalytics);
