import {
  LOGIN,
  GET_USER,
  UPDATE_USER,
  LOGOUT,
  CREATE_USER,
  RECOVER_PASSWORD,
  CHANGE_PASSWORD,
  GET_LEVELS,
  UPDATE_USER_TEAM,
  CLEAN_PROFILE_UPDATE,
  TOGGLE_NEXT_LEVEL,
  UPLOAD_PICTURE,
  GET_PRODUCT,
  HAVE_ONE_SKILL,
  SEND_EMAIL_PRODUCT,
  DELETE_ACCOUNT,
  GET_MISSION,
  GET_GROUPS,
  REMOVE_GROUP,
  GET_GROUP,
  JOIN_GROUP,
  LEAVE_GROUP,
  SEARCH_USER,
  SAVE_GROUP,
  SEND_INVITATION,
  GET_REFERRALS,
  GET_NEWS,
  GET_COURSES,
  GET_BENEFITS,
  GET_COURSE,
  GET_COURSE_TEST,
  CHECK_LESSON,
  GET_BUDGET,
  SAVE_BUDGET,
  GET_MISSIONS,
  SAVE_GOAL,
  GET_PROFILE_DETAIL,
  GET_CATEGORIES,
  GET_USER_GROUP_TESTS,
  GET_COURSES_SHORT,
  GET_WORLDS,
  SAVE_WORLDS,
  UPDATE_WORLD,
  GET_PRODUCTS_BY_REGION,
  GET_REGIONS,
  GET_PRODUCT_BY_ID,
  GET_NEW_PROFILE,
  POST_EMAIL_MARKETPLACE,
  DELETE_BUDGET,
  GET_ALL_LEVELS,
  GET_GP_PRODUCT_LIST,
  GET_GP_SERVICE_LIST,
  PAY_GP_TX,
  SEND_GP_TX,
  GET_ALL_DOCUMENT_TYPES,
  GET_PREMIUM_GROUP_DETAIL,
  GET_USER_BBVA_PRODUCTS,
  GET_USER_LEVEL_PROGRESS,
  GET_USER_MEDALS,
  GET_USER_SKILLS,
  REFUND_GP,
  SET_USER_BBVA_PRODUCT,
  GET_MOTIVES,
  GET_METAS,
  UPDATE_USER_PROFILE,
  UPDATE_MOTIVES,
  UPDATE_METAS,
  SEARCH_USERS,
} from '@constants/actionNames';
import actionNames, {
  GET_ADMIN_GROUP_DATA,
  GET_GROUPS_SEARCH_LIST,
  GET_USER_DAILY_PROGRESS,
  GET_USER_EVALUATIONS,
  GET_USER_STATISTICS,
  POST_NEW_USER_EVALUATION,
  REMOVE_USER_FROM_GROUP,
  SET_LIVES,
  SET_PACE,
} from '../../constants/actionNames';

const initialState = {
  changePasswordData: false,
  changePasswordError: false,
  changePasswordLoading: false,
  recoverPasswordData: false,
  recoverPasswordError: false,
  recoverPasswordLoading: false,
  createUserError: false,
  allLevelsData: false,
  allLevelsLoading: false,
  levelsData: false,
  levelsLoading: false,
  loginData: false,
  loginLoading: false,
  loginError: false,
  userData: false,
  userLoading: false,
  userError: false,
  postUserData: false,
  postUserLoading: false,
  postUserError: false,
  uploadPictureData: false,
  haveOneSkillData: false,
  deleteAccountData: false,
  groupsData: false,
  gpProductListData: false,
  gpServiceListData: false,
  payGpTxData: false,
  payGpTxLoading: false,
  payGpTxError: false,
  refundGpData: false,
  sendGpTxData: false,
  sendGpTxLoading: false,
  sendGpTxError: false,
  removeGroupData: false,
  removeGroupLoading: false,
  removeGroupError: false,
  groupData: false,
  adminGroupData: false,
  adminGroupLoading: false,
  adminGroupError: false,
  joinGroupData: false,
  searchUserData: false,
  saveGroupData: false,
  sendInvitationData: false,
  referralsData: false,
  newsData: false,
  benefitsData: false,
  coursesData: false,
  coursesShortData: false,
  coursesShortLoading: false,
  coursesLoading: false,
  coursesTestData: false,
  coursesTestLoading: false,
  checkLessonData: false,
  checkLessonLoading: false,
  budgetData: false,
  budgetLoading: false,
  saveBudgetData: false,
  saveBudgetLoading: false,
  missionsData: false,
  missionsLoading: false,
  saveGoalData: false,
  saveGoalLoading: false,
  profileData: false,
  profileDataLoading: false,
  categoriesData: false,
  categoriesDataLoading: false,
  userTestGroupData: false,
  userTestGroupLoading: false,
  worldsData: false,
  worldsLoading: false,
  saveWorldsData: false,
  saveWorldsLoading: false,
  productsByRegion: false,
  productsByRegionLoading: false,
  regions: false,
  regionsLoading: false,
  premiumGroupData: false,
  premiumGroupLoading: false,
  premiumGroupError: false,
  productData: false,
  productLoading: false,
  newProfileData: false,
  // -- #BBVA --
  documentTypesData: false,
  documentTypesLoading: false,
  documentTypesError: false,
  userBbvaProductsData: false,
  userBbvaProductsLoading: false,
  userBbvaProductsError: false,
  setUserBbvaProductData: false,
  setUserBbvaProductLoading: false,
  setUserBbvaProductError: false,
  // -- #V3 --
  userMedalsData: false,
  userMedalsLoading: false,
  userMedalsError: false,
  userSkillsData: false,
  userSkillsLoading: false,
  userSkillsError: false,
  userLevelProgressData: false,
  userLevelProgressLoading: false,
  userLevelProgressError: false,
  userDailyProgressData: false,
  userDailyProgressLoading: false,
  userDailyProgressError: false,
  userStatisticsData: false,
  userStatisticsLoading: false,
  userStatisticsError: false,
  metasData: false,
  metasLoading: false,
  metasError: false,
  motivesData: false,
  motivesLoading: false,
  motivesError: false,
  searchUsersData: false,
  searchUsersLoading: false,
  searchUsersError: false,
  groupsSearchListData: false,
  groupsSearchListLoading: false,
  groupsSearchListError: false,
  userEvaluationsData: false,
  userEvaluationsLoading: false,
  userEvaluationsError: false,
  paceData: false,
  paceLoading: false,
  paceError: false,
  livesData: false,
  livesLoading: false,
  livesError: false,
  removeUserFromGroupData: false,
  removeUserFromGroupLoading: false,
  removeUserFromGroupError: false,
};

const authReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case LOGIN:
      return { ...state, ...payload };
    case LOGOUT:
      return { ...state, ...payload };
    case GET_USER:
      return { ...state, ...payload };
    case GET_WORLDS:
      return { ...state, ...payload };
    case GET_LEVELS:
      return { ...state, ...payload };
    case GET_ALL_LEVELS:
      return { ...state, ...payload };
    case UPDATE_USER:
      return { ...state, ...payload };
    case CREATE_USER:
      return { ...state, ...payload };
    case RECOVER_PASSWORD:
      return { ...state, ...payload };
    case CHANGE_PASSWORD:
      return { ...state, ...payload };
    case UPDATE_USER_TEAM: {
      const newState = { ...state };
      newState.userData.team = payload;
      return newState;
    }
    case UPLOAD_PICTURE: {
      if (payload.uploadPictureData) {
        const newState = { ...state };
        newState.userData.profilePicture = payload.uploadPictureData.url;
        return { ...newState, ...payload };
      }
      return { ...state, ...payload };
    }
    case UPDATE_WORLD: {
      const newState = { ...state };
      if (newState.userData) {
        newState.userData.worldId = payload.worldId;
      }
      return { ...newState, ...payload };
    }
    case CLEAN_PROFILE_UPDATE:
      return { ...state, ...payload };
    case TOGGLE_NEXT_LEVEL: {
      const newState = { ...state };
      newState.userData.nextLevel = payload;
      return newState;
    }
    case HAVE_ONE_SKILL:
      return { ...state, ...payload };
    case GET_PRODUCT:
      return { ...state, ...payload };
    case SEND_EMAIL_PRODUCT:
      return { ...state, ...payload };
    case DELETE_ACCOUNT:
      return { ...state, ...payload };
    case GET_MISSION:
      return { ...state, ...payload };
    case GET_GROUPS:
      return { ...state, ...payload };
    case GET_GROUP:
      return { ...state, ...payload };
    case GET_PREMIUM_GROUP_DETAIL:
      return { ...state, ...payload };
    case GET_ADMIN_GROUP_DATA:
      return { ...state, ...payload };
    case GET_GP_PRODUCT_LIST: {
      const newState = { ...state };
      newState.gpProductListData = payload.getGpProductListData;
      return newState;
    }
    case GET_GP_SERVICE_LIST: {
      const newState = { ...state };
      newState.gpServiceListData = payload.gpServiceListData;
      return newState;
    }
    case PAY_GP_TX: {
      return { ...state, ...payload };
    }
    case SEND_GP_TX: {
      return { ...state, ...payload };
    }
    case REMOVE_GROUP:
      return { ...state, ...payload };
    case JOIN_GROUP:
      return { ...state, ...payload };
    case LEAVE_GROUP:
      return { ...state, ...payload };
    case REMOVE_USER_FROM_GROUP:
      return { ...state, ...payload };
    case SEARCH_USER:
      return { ...state, ...payload };
    case SAVE_GROUP:
      return { ...state, ...payload };
    case SEND_INVITATION:
      return { ...state, ...payload };
    case GET_REFERRALS:
      return { ...state, ...payload };
    case GET_NEWS:
      return { ...state, ...payload };
    case GET_COURSES:
      return { ...state, ...payload };
    case GET_BENEFITS:
      return { ...state, ...payload };
    case GET_COURSE:
      return { ...state, ...payload };
    case GET_COURSE_TEST:
      return { ...state, ...payload };
    case CHECK_LESSON:
      return { ...state, ...payload };
    case GET_BUDGET:
      return { ...state, ...payload };
    case SAVE_BUDGET:
      return { ...state, ...payload };
    case DELETE_BUDGET:
      return { ...state, ...payload };
    case GET_MISSIONS:
      return { ...state, ...payload };
    case SAVE_GOAL:
      return { ...state, ...payload };
    case SAVE_WORLDS:
      return { ...state, ...payload };
    case GET_PROFILE_DETAIL:
      return { ...state, ...payload };
    case GET_CATEGORIES:
      return { ...state, ...payload };
    case GET_USER_GROUP_TESTS:
      return { ...state, ...payload };
    case GET_COURSES_SHORT:
      return { ...state, ...payload };
    case GET_PRODUCTS_BY_REGION:
      return { ...state, ...payload };
    case GET_REGIONS:
      return { ...state, ...payload };
    case GET_PRODUCT_BY_ID:
      return { ...state, ...payload };
    case GET_NEW_PROFILE:
      return { ...state, ...payload };
    case POST_EMAIL_MARKETPLACE:
      return { ...state, ...payload };
    case GET_ALL_DOCUMENT_TYPES:
      return { ...state, ...payload };
    case GET_USER_BBVA_PRODUCTS:
      return { ...state, ...payload };
    case SET_USER_BBVA_PRODUCT:
      return { ...state, ...payload };
    case GET_USER_MEDALS:
      return { ...state, ...payload };
    case GET_USER_SKILLS:
      return { ...state, ...payload };
    case GET_USER_LEVEL_PROGRESS:
      return { ...state, ...payload };
    case GET_USER_DAILY_PROGRESS:
      return { ...state, ...payload };
    case GET_USER_STATISTICS:
      return { ...state, ...payload };
    case GET_MOTIVES:
      return { ...state, ...payload };
    case GET_METAS:
      return { ...state, ...payload };
    case UPDATE_USER_PROFILE:
      return { ...state, ...payload };
    case UPDATE_MOTIVES:
      return { ...state, motivesData: payload };
    case UPDATE_METAS:
      return { ...state, metasData: payload };
    case SEARCH_USERS:
      return { ...state, ...payload };
    case GET_GROUPS_SEARCH_LIST:
      return { ...state, ...payload };
    case GET_USER_EVALUATIONS:
      return { ...state, ...payload };
    case POST_NEW_USER_EVALUATION:
      return { ...state, ...payload };
    case SET_PACE:
      return { ...state, userData: { ...state.userData, pace: payload.paceData } };
    case SET_LIVES:
      return { ...state, userData: { ...state.userData, lives: payload.livesData } };
    default:
      return state;
  }
};

export default authReducer;
