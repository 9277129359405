import * as Sentry from '@sentry/browser';
import dotenv from 'dotenv';
import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from '@redux/store';
import { alfiUserId, alfiUserEmail } from '@analytics/constants';
import './scss/common.scss';

import App from './app';
import 'normalize.css';
import * as serviceWorker from './serviceWorker';
import ScrollToTop from './ScrollToTop';

const sentryConfig = {
  dsn: process.env.REACT_APP_SENTRY_DNS,
  environment: process.env.REACT_APP_SENTRY_ENV,
};

if (process.env.NODE_ENV === 'production') {
  sentryConfig.version = process.env.SENTRY_VERSION;
}

Sentry.init(sentryConfig);
dotenv.config();

if (process.env.REACT_APP_GA_ID) {
  ReactGA.initialize(process.env.REACT_APP_GA_ID, {
    gaOptions: {
      userId: alfiUserId,
    },
  });
  if (alfiUserId) {
    /* eslint-disable no-debugger */
    window.mixpanel.identify(alfiUserId);
  }
}

if (alfiUserId) {
  Sentry.configureScope((scope) => {
    scope.setUser({ id: alfiUserId, email: alfiUserEmail });
  });
}

// if (process.env.REACT_APP_FB_PIXEL_ID) {
//   /* eslint-disable */
//   !function(f,b,e,v,n,t,s)
//     {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
//     n.callMethod.apply(n,arguments):n.queue.push(arguments)};
//     if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
//     n.queue=[];t=b.createElement(e);t.async=!0;
//     t.src=v;s=b.getElementsByTagName(e)[0];
//     s.parentNode.insertBefore(t,s)}(window,document,'script',
//     'https://connect.facebook.net/en_US/fbevents.js');
//     fbq('init', process.env.REACT_APP_FB_PIXEL_ID);
//     fbq('track', 'PageView');
// }

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <ScrollToTop>
        <App />
      </ScrollToTop>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
